import React from 'react';
import { ReactNode } from 'react';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { MoveToTop } from '../../../animations';

interface CInterface extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const BodyItem: FC<CInterface> = ({ children, ...props }) => {
  return (
    <motion.div
      animate="visible"
      initial="hidden"
      variants={MoveToTop}
      className={[
        'inline-flex px-6 py-5 w-full text-sm text-gray-500 relative whitespace-nowrap items-center',
        props.className
      ].join(' ')}
      style={props?.style}
    >
      {children}
    </motion.div>
  );
};

export default React.memo(BodyItem);
