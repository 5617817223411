import React from 'react';
import Footer from './Footer';
import Header from './Header';
import SideBar from './SideBar';

const HeaderLayout: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
  return (
    <React.Fragment>
      <div
        className="flex"
        data-type="body"
      >
        <SideBar />

        <main
          className="flex-1 shrink-0 min-h-screen flex flex-col"
          data-type="content"
        >
          <Header />
          <div className="flex-1">{children}</div>
          <Footer />
        </main>
      </div>
    </React.Fragment>
  );
};

export default HeaderLayout;
