import React from 'react';
import { ReactNode } from 'react';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { MoveToTop } from '../../../animations';

const HeadItem: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <motion.div
      animate="visible"
      initial="hidden"
      variants={MoveToTop}
      className="px-6 py-5 text-sm text-gray-500 font-semibold whitespace-nowrap"
    >
      {children}
    </motion.div>
  );
};

export default React.memo(HeadItem);
