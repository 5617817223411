import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Button from 'src/components/shared/Button';
import generateAlert from 'src/helper/generateAlert';
import { storeResetEmail } from 'src/reducers/auth';
import prepareRequest from '../../helper/prepareRequest';

interface AuthInterface {
  email_mobile?: string;
}

const FormBody: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalValues = {
    email_mobile: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        { url: 'reset_password/send', method: 'post', data: values },
        (data, error) => {
          if (error) return setErrors(() => error);

          helper.resetForm();
          generateAlert(data.message, 'success');
          dispatch(storeResetEmail({ email: values.email_mobile as string }));
          navigate('/account/reset-password', { replace: true });
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-3"
      onSubmit={handleSubmit}
    >
      <div className="mb-6 space-y-4 text-center">
        <p className="text-3xl font-bold">{t('auth.forgot-password.title')}</p>
        <p className="text-gray-500">{t('auth.forgot-password.content')}</p>
      </div>
      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('form.email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>

      <div className="!mt-8 space-y-2">
        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('send-email')}</span>
        </Button>{' '}
        <p className="flex items-center gap-4 !my-4">
          <span className="flex-1 h-px bg-gray-200"></span>
          <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
          <span className="flex-1 h-px bg-gray-200"></span>
        </p>
        <p className="text-gray-600 text-center text-sm">
          <Link
            className="font-medium text-primary hover:underline transition"
            to="/account/login"
          >
            <span>{t('sign-in')}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

const ForgotPassword: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="max-w-screen-sm m-auto w-full">
        <FormBody />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
