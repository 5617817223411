import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import DeleteButton from 'src/components/shared/DeleteButton';
import Mobile from 'src/components/shared/Mobile';
import Modal from 'src/components/shared/Modal';
import Password from 'src/components/shared/Password';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function Admins() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('admins', { params }).then(({ data }) => {
        setPagination(() => data.result?.admins?.pagination || {});
        return data.result?.admins?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.email')}</th>
            <th>{t('form.mobile')}</th>
            <th>{t('form.role')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.email || '-'}</td>
                <td>{item.mobile || '-'}</td>
                <td>{item.role?.name || '-'}</td>

                <td>
                  <ChangeStatus
                    pathname={'admins/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      refetch={refetch}
                      item={item}
                    />
                    <DeleteButton
                      pathname={'admins/' + item.id}
                      refetch={refetch}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            {/* <Link
              to="/users/create"
              className="btn-with-icon !text-gray-600 bg-gray-100"
            >
              <span>{t('add-new')}</span>
            </Link> */}
            <AddForm />
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function AddForm({ refetch }: { refetch?: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any[]>([]);
  useEffect(() => {
    setErrors({});
    if (!visible) return;

    getRoles();
  }, [visible]);

  function getRoles() {
    prepareRequest(
      {
        url: 'roles',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setRoles(() => data.result?.roles || []);
      }
    );
  }

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: {},
    submitHandler: (values, helper) => {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'admins/add',
          method: 'post',
          data: {
            ...values,
            mobile: values.mobile?.replace(/\s/gi, '')
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <span>{t('add-new')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-sm"
        title={t('add-new')}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              value={values.name}
              name="name"
              onChange={handleChange}
              placeholder="..."
            />
            <p className="form-error">{errors.name}</p>
          </div>

          <div className="form-group">
            <p className="form-label">{t('form.email')}</p>
            <input
              type="email"
              className="form-input form-outline"
              value={values.email}
              name="email"
              onChange={handleChange}
              placeholder="example@example.com"
            />
            <p className="form-error">{errors.email}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.mobile')}</p>
            <Mobile
              className="form-input form-outline"
              value={values.mobile}
              name="mobile"
              onChange={handleChange}
            />
            <p className="form-error">{errors.mobile}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.password')}</p>
            <Password
              className="form-input form-outline"
              value={values.password}
              name="password"
              onChange={handleChange}
            />
            <p className="form-error">{errors.password}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.confirm-password')}</p>
            <Password
              className="form-input form-outline"
              value={values.confirmation_password}
              name="confirmation_password"
              onChange={handleChange}
            />
            <p className="form-error">{errors.confirmation_password}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.role')}</p>
            <select
              className="form-select form-outline"
              value={values.role_id}
              name="role_id"
              onChange={handleChange}
              defaultValue=""
            >
              <option value="">-- {t('select')} --</option>
              {roles.map((role) => (
                <option
                  value={role.id}
                  key={role.id}
                >
                  {role.name}
                </option>
              ))}
            </select>
            <p className="form-error">{errors.role_id}</p>
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            {t('submit')}
          </Button>
        </form>
      </Modal>
    </>
  );
}

function UpdateForm({ item, refetch }: { item: any; refetch?: any }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any[]>([]);
  useEffect(() => {
    setErrors({});
    if (!visible) return;

    getRoles();
  }, [visible]);

  function getRoles() {
    prepareRequest(
      {
        url: 'roles',
        params: { is_active: 1 }
      },
      (data) => {
        if (data) setRoles(() => data.result?.roles || []);
      }
    );
  }

  const {
    formik: { handleChange, handleSubmit, values, setFieldValue }
  } = useForm({
    initialValues: { ...item, role_id: item.role?.id },
    submitHandler: (values, helper) => {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'admins/update',
          method: 'post',
          data: {
            ...values,
            mobile: values.mobile?.replace(/\s/gi, '')
          }
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          helper.resetForm();
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        size="!max-w-screen-sm"
        title={`${t('edit')} - (${item.name})`}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              value={values.name}
              name="name"
              onChange={handleChange}
              placeholder="..."
            />
            <p className="form-error">{errors.name}</p>
          </div>

          <div className="form-group">
            <p className="form-label">{t('form.email')}</p>
            <input
              type="email"
              className="form-input form-outline"
              value={values.email}
              name="email"
              onChange={handleChange}
              placeholder="example@example.com"
            />
            <p className="form-error">{errors.email}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.mobile')}</p>
            <Mobile
              className="form-input form-outline"
              value={values.mobile}
              name="mobile"
              onChange={handleChange}
            />
            <p className="form-error">{errors.mobile}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.password')}</p>
            <Password
              className="form-input form-outline"
              value={values.password}
              name="password"
              onChange={handleChange}
            />
            <p className="form-error">{errors.password}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.confirm-password')}</p>
            <Password
              className="form-input form-outline"
              value={values.confirmation_password}
              name="confirmation_password"
              onChange={handleChange}
            />
            <p className="form-error">{errors.confirmation_password}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.role')}</p>
            <select
              className="form-select form-outline"
              value={values.role_id}
              name="role_id"
              onChange={handleChange}
              defaultValue=""
            >
              <option value="">-- {t('select')} --</option>
              {roles.map((role) => (
                <option
                  value={role.id}
                  key={role.id}
                >
                  {role.name}
                </option>
              ))}
            </select>
            <p className="form-error">{errors.role_id}</p>
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            {t('submit')}
          </Button>
        </form>
      </Modal>
    </>
  );
}
