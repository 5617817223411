import { useQuery } from '@tanstack/react-query';
import { serialize } from 'object-to-formdata';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import DeleteButton from 'src/components/shared/DeleteButton';
import Image from 'src/components/shared/Image';
import InputFile from 'src/components/shared/InputFile';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function Categories() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('categories', { params }).then(({ data }) => {
        setPagination(() => data.result?.categories?.pagination || {});
        return data.result?.categories?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.image')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
            <th>{t('form.price')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Image
                    src={item.image}
                    alt={item.name}
                    className="w-10 h-10 object-contain"
                  />
                </td>
                <td>{item.name || '-'}</td>
                <td>{item.alt_name || '-'}</td>
                <td>{CurrencyFormatter(item.price || 0)}</td>
                <td>
                  <ChangeStatus
                    pathname={'categories/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>

                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      refetch={refetch}
                      item={item}
                    />
                    <DeleteButton
                      pathname={'categories/delete/' + item.id}
                      refetch={refetch}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <AddForm refetch={refetch} />
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function AddForm({ refetch }: { refetch: () => any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { handleChange, handleSubmit, setFieldValue, values }
  } = useForm({
    initialValues: { image: undefined },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        { url: 'categories/add', method: 'post', data: serialize(values) },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch();
          setVisible(false);
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        type="button"
        onClick={() => setVisible(true)}
      >
        <span>{t('add-new')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.image')}</p>
            <InputFile
              accept="image/*"
              onValueChange={(value) => setFieldValue('image', value)}
            />
            <p className="form-error">{errors['image']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              name="name"
              className="form-input"
              id="name"
              value={values.name}
              onChange={handleChange}
            />
            <p className="form-error">{errors['name']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-name')}</p>
            <input
              type="text"
              name="alt_name"
              className="form-input"
              id="alt_name"
              value={values.alt_name}
              onChange={handleChange}
            />
            <p className="form-error">{errors['alt_name']}</p>
          </div>

          <Button
            type="submit"
            disabled={disabled}
            variant="primary"
          >
            <span>{t('submit')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}

function UpdateForm({ refetch, item }: { refetch: () => any; item: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { handleChange, handleSubmit, setFieldValue, values }
  } = useForm({
    initialValues: { ...item },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      const fd = serialize(values);
      if (!(values.image instanceof File)) fd.delete('image');

      prepareRequest({ url: 'categories/update', method: 'post', data: fd }, (data, error) => {
        if (error) return setErrors(() => error);
        generateAlert(data.message, 'success');
        refetch();
        setVisible(false);
        formikHelpers.resetForm();
      }).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        type="button"
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${t('edit')} - (${item.name})`}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.image')}</p>
            <InputFile
              accept="image/*"
              onValueChange={(value) => setFieldValue('image', value)}
            />
            <p className="form-error">{errors['image']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              name="name"
              className="form-input"
              id="name"
              value={values.name}
              onChange={handleChange}
            />
            <p className="form-error">{errors['name']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-name')}</p>
            <input
              type="text"
              name="alt_name"
              className="form-input"
              id="alt_name"
              value={values.alt_name}
              onChange={handleChange}
            />
            <p className="form-error">{errors['alt_name']}</p>
          </div>

          <Button
            type="submit"
            disabled={disabled}
            variant="primary"
          >
            <span>{t('submit')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
