import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from 'src/components/shared/MiniDashboardSingleCard';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

const Bills: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    status: '',
    is_paid: '',
    search_key: ''
  });
  const [params, setParams] = useSearchParams({
    page: '1'
  });

  const [cards, setCards] = React.useState<ItemInterface[]>([
    {
      title: t('bills-count'),
      key: 'bills_count',
      value: 0,
      icon: 'ri:hospital-line',
      color: 'text-gray-800'
    },
    {
      title: t('paid-amounts'),
      key: 'paid_amount',
      value: 0,
      icon: 'ic:baseline-attach-money',
      color: 'text-gray-800'
    },
    {
      title: t('unpaid-amounts'),
      key: 'total_unpaid_amount',
      value: 0,
      icon: 'ic:baseline-money-off',
      color: 'text-gray-800'
    }
  ]);

  React.useEffect(() => {
    GetItems();
  }, [params]);

  async function GetItems() {
    try {
      setIsUpdating(true);

      const { data } = await axiosInstance.get('bills', {
        params
      });
      const { data: items, pagination: responsePaginate } = data?.result?.bills;
      // console.log(data);
      setPagination((values: any) => ({ ...responsePaginate }));
      setResponses(items);
      const summary = data.result?.summary || [];
      setCards((items: ItemInterface[]) => {
        return items.map((item) => {
          if (Object.getPrototypeOf.call(summary, item.key)) {
            const value = summary[item.key as string];
            item.value = value || 0;
            return item;
          }
          return item;
        });
      });
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }

  const MarkAsPaid = React.useCallback(async function (id: string) {
    try {
      setErrors(undefined);
      setIsUpdating(true);

      const { data } = await axiosInstance.post('bills/mark_as_paid/' + id);
      await GetItems();

      // await queryRef.current?.Fetch();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while updating status..');
        }
        return;
      }
      setErrors('Something went wrong while updating status..');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('amount')}</th>
              <th>{t('pages.bookings')}</th>
              <th>{t('deadline-due-date')}</th>
              <th>{t('paid-date')}</th>
              <th>{t('date')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr
                    className="divide-x divide-gray-200"
                    key={index}
                  >
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start tabular-nums">
                      {CurrencyFormatter(item?.amount || 0)}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      {item?.bookings?.length ? (
                        <Link
                          to={'/bills/' + item.id}
                          className="w-fit btn-with-icon outline-btn bg-neutral-500"
                        >
                          <Icon
                            icon="ri:eye-line"
                            width={15}
                          />
                          <span>{t('view')}</span>
                        </Link>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.deadline_due_date} />
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      {item.paid_at ? (
                        <SharedTime date={item.paid_at} />
                      ) : (
                        <button
                          className="w-fit btn-with-icon bg-primary"
                          onClick={() => MarkAsPaid(item.id)}
                        >
                          <Icon
                            icon="fluent:payment-24-regular"
                            width={18}
                          />
                          <span>{t('mark-booking-as-paid')}</span>
                        </button>
                      )}
                    </td>
                    <td className="border-b border-slate-200 p-4 text-slate-600 text-start">
                      <SharedTime date={item.created_at} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!responses?.length}
        title="Bills table"
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              param.set('page', '1');
              return param;
            });
          }
        }}
        loading={isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <HorizontalDatePicker
          type="monthly"
          onChange={({ from, to }) => {
            setParams((param) => {
              param.set('from', moment(from).format('YYYY-MM-DD'));
              param.set('to', moment(to).format('YYYY-MM-DD'));
              param.set('page', '1');
              return param;
            });
          }}
        />
        <div className="grid grid-wrapper gap-4">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        {MEMO_TABLE}
      </div>
    </React.Fragment>
  );
};

export default Bills;
