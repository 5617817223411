import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Map from 'src/components/shared/Map';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('centers', { params }).then(({ data }) => {
        setPagination(() => data.result?.centers?.pagination || {});
        return data.result?.centers?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.owner')}</th>
            <th>{t('form.package')}</th>
            <th>{t('form.region')}</th>
            <th>{t('form.city')}</th>

            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((center: any) => (
              <tr key={center.id}>
                <td>
                  {center.name} - {center.alt_name}
                </td>
                <td>
                  {center.owner?.name}
                  <br />
                  {center.owner?.mobile}
                </td>
                <td>{center.package?.name} </td>
                <td>
                  {center.region?.name} - {center.region?.alt_name}
                </td>
                <td>
                  {center.city?.name} - {center.city?.alt_name}
                </td>

                <td>
                  <ChangeStatus
                    pathname={'centers/update_is_active/' + center.id}
                    refetch={refetch}
                    active={!!center.is_active}
                  />
                </td>
                <td>
                  <SharedTime date={center.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateCenterLocation
                      refetch={refetch}
                      item={center}
                    />
                    <UpdateCenterOwnerEmail
                      refetch={refetch}
                      item={center}
                    />
                    <UpdateCenterPackage
                      refetch={refetch}
                      item={center}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => <></>}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function UpdateCenterPackage({ refetch, item }: { refetch: () => any; item: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [packages, setPackages] = useState<any[]>([]);

  useEffect(() => {
    if (!visible) return;
    getPackages();
  }, [visible]);

  function getPackages() {
    prepareRequest({ url: 'packages', params: { is_active: 1 } }, (data) =>
      setPackages(() => data.result?.packages || [])
    );
  }

  const {
    formik: { handleChange, values, handleSubmit }
  } = useForm({
    initialValues: { center_id: item.id, package_id: item.package?.id },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'centers/update_package',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        variant="light"
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('update-package')}</span>
      </Button>
      <Modal
        size="max-w-screen-sm"
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${item.name} - ${item.owner?.name}`}
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div className="form-group">
            <p className="form-label">{t('form.package')}</p>
            <select
              name="package_id"
              id="package_id"
              className="form-select form-outline"
              value={values.package_id}
              defaultValue=""
              onChange={handleChange}
            >
              <option value="">-- {t('select')} --</option>
              {packages.map((pack: any) => (
                <option
                  value={pack.id}
                  key={pack.id}
                >
                  {pack.name}
                </option>
              ))}
            </select>
            <p className="form-error">{errors['package_id']}</p>
            <p className="form-error">{errors['id']}</p>
          </div>
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}

function UpdateCenterLocation({ refetch, item }: { refetch: () => any; item: any }) {
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [regions, setRegions] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  useEffect(() => {
    if (!visible) return;
    getRegions();
    item.region?.id && getCities(undefined, item.region?.id);
    item.region?.id && item.city?.id && getDistricts(undefined, item.region?.id, item.city?.id);
  }, [visible, item]);

  function getRegions() {
    prepareRequest({ url: 'regions', params: { is_active: 1 } }, (data) =>
      setRegions(() => data.result?.regions || [])
    );
  }

  const {
    formik: { handleChange, values, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: {
      center_id: item.id,
      region_id: item.region?.id,
      city_id: item.city?.id,
      district_id: item.district?.id,
      lat: parseFloat(item.lat),
      lng: parseFloat(item.lng),
      address: item.address
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'centers/update_location',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  function getCities(q?: string, region_id?: any) {
    prepareRequest(
      {
        url: 'cities',
        params: { is_active: 1, page: 1, search_key: q, region_id, city_id: values.city_id }
      },
      (data) => {
        if (data) setCities(() => data.result?.cities?.data || []);
      }
    );
  }

  function getDistricts(q?: string, region_id?: any, city_id?: any) {
    prepareRequest(
      {
        url: 'districts',
        params: {
          is_active: 1,
          page: 1,
          search_key: q,
          region_id,
          city_id,
          district_id: values.district_id
        }
      },
      (data) => {
        if (data) setDistricts(() => data.result?.districts?.data || []);
      }
    );
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <Icon
          icon="material-symbols:edit-location-outline-rounded"
          width="22"
          height="22"
        />
      </Button>
      <Modal
        size="max-w-screen-sm"
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${item.name} - ${item.owner?.name}`}
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div className="form-group">
            <p className="form-label">{t('form.address')}</p>
            <input
              type="text"
              className="form-input form-outline"
              name="address"
              id="address"
              value={values.address}
              onChange={handleChange}
            />
            <Map
              value={{
                lat: values.lat,
                lng: values.lng,
                address: values.address
              }}
              onChange={({ lat, lng, address }) => {
                setFieldValue('lat', lat);
                setFieldValue('lng', lng);
                setFieldValue('address', address);
              }}
            />
            <p className="form-error">{errors['address']}</p>
            <p className="form-error">{errors['lat']}</p>
            <p className="form-error">{errors['lng']}</p>
            <p className="form-error">{errors['id']}</p>
          </div>

          <div className="form-group">
            <label className="form-label">{t('form.region')}</label>
            <Select
              type={'single'}
              options={regions}
              value={values.region_id}
              onSelect={function (value: any): void {
                setFieldValue('region_id', value);
                setFieldValue('city_id', undefined);
                setFieldValue('district_id', undefined);
                getCities(undefined, value);
              }}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.city')}</label>
            <Select
              type={'single'}
              options={cities}
              value={values.city_id}
              onSelect={function (value: any): void {
                setFieldValue('city_id', value);
                setFieldValue('district_id', undefined);
                getDistricts(undefined, values.region_id, value);
              }}
              onSearchChange={(v) => getCities(v, values.region_id)}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.city_id ? <span className="form-error">{errors?.city_id}</span> : null}
          </div>
          <div className="form-group col-span-full">
            <label className="form-label">{t('form.district')}</label>
            <Select
              type={'single'}
              options={districts}
              value={values.district_id}
              onSelect={function (value: any): void {
                setFieldValue('district_id', value);
              }}
              onSearchChange={(v) => getDistricts(v, values.region_id, values.city_id)}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.district_id ? <span className="form-error">{errors?.district_id}</span> : null}
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}

function UpdateCenterOwnerEmail({ refetch, item }: { refetch: () => any; item: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { handleChange, values, handleSubmit }
  } = useForm({
    initialValues: { center_id: item.id, email: item.owner?.email },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'centers/update_email',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        variant="light"
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('update-email')}</span>
      </Button>
      <Modal
        size="max-w-screen-sm"
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${item.name} - ${item.owner?.name}`}
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div className="form-group">
            <p className="form-label">{t('form.email')}</p>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              value={values.email}
              onChange={handleChange}
              className="form-input form-outline"
            />
            <p className="form-error">{errors['email']}</p>
            <p className="form-error">{errors['id']}</p>
          </div>
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
