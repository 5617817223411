import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import InputFile from 'src/components/shared/InputFile';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import { useAlert } from 'src/hooks/alerts';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [profile, setProfile] = React.useState<any>(user);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <UpdateFormBody schema={profile} />
      </div>
    </React.Fragment>
  );
}

interface FormInterface {
  name: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  image: string | undefined;
  password: string | undefined;
  c_password: string | undefined;
}

const UpdateFormBody = ({ schema }: { schema: FormInterface & { id: string } }): JSX.Element => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const alert = useAlert();

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      let fd: FormData;
      fd = new FormData();

      for (const key in values) {
        const item = values[key as keyof typeof schema];

        if (item) {
          if (key === 'image') {
            if (Object.getPrototypeOf(item).constructor.name === 'File') {
              fd.append(key, item, item?.name);
            } else {
              fd.delete(key);
            }
          } else {
            fd.append(key, item);
          }
        }
      }

      const { data } = await axiosInstance.post('profile/update', fd);
      generateAlert(data.message, 'success');
      helper.resetForm();
      window.location.reload();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-6"
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <div className="space-y-4">
          <div className="form-group">
            <label
              htmlFor="image"
              className="form-label"
            >
              {t('form.image')}
            </label>
            <InputFile
              defaultValue={formik.values.image instanceof File ? formik.values.image : null}
              onValueChange={function (e: any): void {
                formik.setFieldValue('image', e);
              }}
              accept="image/*"
            />
            {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.name')}</label>
            <input
              type="text"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.name}
              onChange={(e) => handleChange('name', e)}
            />
            {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.email')}</label>
            <input
              type="email"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.email}
              onChange={(e) => handleChange('email', e)}
            />
            {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.mobile')}</label>
            <input
              type="tel"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.mobile}
              onChange={(e) => handleChange('mobile', e)}
            />
            {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
          </div>
        </div>
      </Card>

      <Card>
        <div className="space-y-4">
          <div className="form-group">
            <label className="form-label">{t('form.c-password')}</label>
            <input
              type="password"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.c_password}
              onChange={(e) => handleChange('c_password', e)}
            />
            {errors?.c_password ? <span className="form-error">{errors?.c_password}</span> : null}
          </div>
          <div className="form-group">
            <label className="form-label">{t('form.new-password')}</label>
            <input
              type="password"
              autoComplete="off"
              placeholder="..."
              className="form-input form-outline"
              value={formik.values.password}
              onChange={(e) => handleChange('password', e)}
            />
            {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
          </div>
        </div>
      </Card>

      <div className="inline-flex gap-3 flex-wrap">
        <Button
          variant="primary"
          disabled={disabled}
          type="submit"
        >
          <span>{t('save-changes')}</span>
        </Button>
      </div>
    </form>
  );
};

export default Profile;
