import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Button from 'src/components/shared/Button';
import Password from 'src/components/shared/Password';
import { saveUserInfo, storeToken } from 'src/reducers/auth';
import prepareRequest from '../../helper/prepareRequest';

interface AuthInterface {
  email_mobile?: string;
  password?: string;
}

const FormBody: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest({ url: 'login', method: 'post', data: values }, (data, error) => {
        if (error) return setErrors(() => error);
        const token = data.result.access_token;
        const user = data.result.profile;
        localStorage.setItem('@token', token);
        helper.resetForm();

        dispatch(storeToken(token));
        dispatch(saveUserInfo({
          ...user,
          permissions: data.result.permissions || []
        }));
        navigate(data.result.go_settings ? '/settings' : '/', { replace: true });
      }).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        console.log('submission from form');
        handleSubmit();
      }}
    >
      <div className="mb-6 space-y-4 text-center">
        <p className="text-3xl font-bold">{t('sign-in')}</p>
      </div>
      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('form.email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>
      <div className="form-group">
        <div className="flex items-center justify-between gap-3">
          <label
            htmlFor="password"
            className="form-label"
          >
            {t('form.password')}
          </label>
          <Link
            className="font-medium text-primary hover:underline transition text-sm"
            to="/account/forgot-password"
          >
            <span>{t('pages.forgot-password')}</span>
          </Link>
        </div>
        <Password
          name="password"
          id="password"
          placeholder="••••••"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="!mt-8 space-y-2">
        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('sign-in')}</span>
        </Button>
      </div>
    </form>
  );
};

const Login: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="max-w-screen-sm m-auto w-full">
        <FormBody />
      </div>
    </AuthWrapper>
  );
};

export default Login;
