import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Image from 'src/components/shared/Image';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import ShowOfferInMain from 'src/components/shared/ShowOfferInMain';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import usePermissions from 'src/hooks/usePermissions';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });
  const { hasAccess } = usePermissions();
  const navigate = useNavigate();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('offers', { params }).then(({ data }) => {
        setPagination(() => data.result?.offers?.pagination || {});
        return data.result?.offers?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.image')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
            <th>{t('form.center')}</th>
            <th>{t('form.advance-payment')}</th>
            <th>{t('form.main-price')}</th>
            <th>{t('form.price')}</th>
            <th>{t('show-in-main')}</th>
            <th>{t('sales')}</th>
            <th>{t('form.discount')}</th>

            <th>{t('date')}</th>
            <th></th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Image
                    src={item.image}
                    alt={item.name}
                    title={item.name}
                    width={40}
                    height={40}
                    className="w-8 h-8 shrink-0 block object-contain"
                  />
                </td>
                <td>{item.name || '-'}</td>
                <td>{item.alt_name || '-'}</td>
                <td>
                  {item.center?.name} - {item.center?.alt_name}
                </td>

                <td>{CurrencyFormatter(item.advance_payment || 0)}</td>
                <td>{CurrencyFormatter(item.main_price || 0)}</td>
                <td>{CurrencyFormatter(item.price || 0)}</td>

                <td>
                  <ShowOfferInMain
                    pathname={'offers/update_show_in_main/' + item.id}
                    refetch={refetch}
                    active={!!item.show_in_main}
                  />
                </td>
                <td>{item.sales_count || 0}</td>
                <td>{item.discount || '-'}</td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <ShowOfferDetails item={item} />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => <></>}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function ShowOfferDetails({ item }: { item: any }) {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="button"
        className="max-w-fit"
        onClick={() => setVisible(true)}
      >
        <span>{t('view')}</span>
      </Button>
      <Modal
        title={item.name}
        visible={visible}
        handleClose={() => setVisible(false)}
        size="max-w-screen-md"
      >
        <div className="space-y-3">
          <Image
            src={item.image}
            alt={item.name}
            width="720"
            height="420"
            className="w-full h-auto object-contain"
          />
          <ul className="flex items-center flex-wrap gap-2">
            <li>
              <p className="flex items-center gap-2 py-2 px-2.5 rounded bg-gray-100 text-gray-600 text-sm">
                <Icon
                  icon="ri:eye-line"
                  width="18"
                  height="18"
                  className="shrink-0"
                />
                <span>{item.sales_count || 0}</span>
              </p>
            </li>
            <li>
              <p className="flex items-center gap-2 py-2 px-2.5 rounded bg-gray-100 text-gray-600 text-sm">
                <Icon
                  icon="iconoir:coins"
                  width="18"
                  height="18"
                  className="shrink-0"
                />
                <span>{CurrencyFormatter(item.price || 0)}</span>
              </p>
            </li>
            <li>
              <p className="flex items-center gap-2 py-2 px-2.5 rounded bg-gray-100 text-gray-600 text-sm">
                <Icon
                  icon="mingcute:location-line"
                  width="18"
                  height="18"
                  className="shrink-0"
                />
                <span>{item.center?.name || '-'}</span>
              </p>
            </li>
          </ul>
          <div className="space-y-2">
            <h2 className="text-xl font-semibold">{item.name}</h2>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.info }}
            ></div>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.alt_info }}
            ></div>
          </div>

          <div className="space-y-2">
            <p className="font-medium">{t('form.features')}</p>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.features }}
            ></div>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.alt_features }}
            ></div>
          </div>
          <div className="space-y-2">
            <p className="font-medium">{t('form.terms')}</p>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.terms }}
            ></div>
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: item.alt_terms }}
            ></div>
          </div>
          <div className="space-y-2">
            <p className="font-medium">{t('pages.doctors')}</p>
            <ul className="flex items-center flex-wrap gap-2">
              {item.doctors?.map((doctor: any) => (
                <li key={doctor.id}>
                  <p className="flex items-center gap-2 py-2 px-2.5 rounded bg-gray-100 text-gray-600 text-sm">
                    <div>
                      <Image
                        src={doctor.photo}
                        alt={doctor.name}
                        title={doctor.name}
                        width={40}
                        height={40}
                        className="w-8 h-8 shrink-0 block object-contain"
                      />
                    </div>
                    <span>{doctor.name || '-'}</span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}
