import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';

export default function ScrollToTop(): any {
  const { pathname }: Location = useLocation();

  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const lang = i18n.language;
    document.querySelector('html')?.setAttribute('lang', lang);
    document.querySelector('html')?.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr');
    // document.
  }, [pathname, i18n.language]);

  return null;
}
