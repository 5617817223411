import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setTitle } from 'src/reducers/root';
import { RootState } from 'src/store';

const pages: Record<string, string> = {
  '/': 'dashboard',
  '/settings': 'settings',
  '/settings/categories': 'categories',
  '/settings/services': 'services',
  '/centers': 'centers',
  '/bookings': 'bookings',
  '/offers': 'offers',
  '/admins': 'admins',
  '/settings/roles': 'roles',
  '/logs': 'logs',
  '/notifications': 'notifications',
  '/notifications/new': 'new-notification',
  '/messages': 'messages',
  '/settings/advanced': 'advanced-settings',
  '/settings/packages': 'packages',
  '/settings/features': 'features',
  '/settings/regions': 'regions',
  '/settings/cities': 'cities',
  '/settings/districts': 'districts',
  '/settings/rate-types': 'rate-types',
  '/settings/relative-types': 'relative-relations',
  '/settings/email-templates': 'email-templates'
};

export default function CurrentPageTitle() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { title } = useSelector((state: RootState) => state.root);
  const dispatch = useDispatch();

  useEffect(() => {
    let str;

    str = pages[pathname] ? t(`pages.${pages[pathname]}`) : title;

    dispatch(setTitle(str));
  }, [pathname, title]);

  if (!title) return <></>;

  return <p className="font-semibold text-gray-600 truncate">{title}</p>;
}
