import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="p-6 border-t border-t-gray-200 bg-white">
      <div className="flex flex-wrap gap-4 justify-between">
        <p className="text-sm text-gray-600">
          ©{new Date().getFullYear()} {t('copyrights')}
        </p>
        <p className="text-sm text-gray-600">
          {t('developed-by')}{' '}
          <Link
            to="https://ia6g.com?source=portal.meaad.com"
            target="_blank"
            className="text-primary font-medium"
          >
            IA6G - بوابة التطبيقات العالمية
          </Link>
        </p>
      </div>
    </footer>
  );
}
