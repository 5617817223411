import { useQuery } from '@tanstack/react-query';
import { serialize } from 'object-to-formdata';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';
import InputFile from 'src/components/shared/InputFile';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function AdvancedSettings() {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [fields, setFields] = useState<any[]>([]);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page'],
    queryFn: () =>
      axiosInstance.get('settings').then(({ data }) => {
        setInitialValues(() => data?.result?.settings_values || {});
        setFields(() => data?.result?.settings || []);
      })
  });

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({
    initialValues,
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'settings/update',
          method: 'post',
          data: serialize(values, {
            indices: true,
            booleansAsIntegers: true
          })
        },
        (data) => {
          generateAlert(data.message, 'success');
          refetch();
        }
      );
    }
  });

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="p-6">
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        {fields.map((field: any, index) => (
          <Fragment key={index}>
            <p className="text-gray-600">
              {field.tab_title_ar} - {field.tab_title_en}
            </p>
            <Card>
              <div className="grid grid-cold-1 sm:grid-cols-2 gap-4">
                {field.items.map((item: any) => (
                  <div
                    className="form-group"
                    key={item.key}
                  >
                    <p className="form-label">
                      {item.label_ar} - {item.label_en}
                    </p>
                    {['text', 'number', 'checkbox', 'radio'].includes(item.field.type) ? (
                      <input
                        type={item.field.type}
                        name={item.key}
                        value={values[item.key]}
                        onChange={handleChange}
                        className={
                          ['text', 'number'].includes(item.field.type)
                            ? 'form-input form-outline'
                            : item.field.type == 'checkbox'
                            ? 'form-checkbox w-5 h-5 rounded-full'
                            : 'form-radio w-5 h-5 rounded-full'
                        }
                      />
                    ) : item.field.type == 'textarea' ? (
                      <textarea
                        name={item.key}
                        value={values[item.key]}
                        onChange={handleChange}
                        className="form-textarea"
                      ></textarea>
                    ) : item.field.type == 'file' ? (
                      <InputFile
                        onValueChange={function (params) {
                          setFieldValue(item.key, params);
                        }}
                      />
                    ) : item.field.type == 'editor' ? (
                      <Editor
                        value={values[item.key]}
                        onChange={function (value: string | undefined) {
                          setFieldValue(item.key, value);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </Card>
          </Fragment>
        ))}

        <Button
          variant="primary"
          type="submit"
          className="max-w-fit"
        >
          <span>{t('save-changes')}</span>
        </Button>
      </form>
    </div>
  );
}
