import { Icon } from '@iconify/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CurrentPageTitle from 'src/components/shared/CurrentPageTitle';
import Image from 'src/components/shared/Image';
import { RootState } from 'src/store';
import AppLocalization from './AppLocalization';

export default function Header({ bordered = true }: { bordered?: boolean }) {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  function openMenu() {
    const menu = document.querySelector('[data-type="menu"]') as HTMLElement;
    menu.classList.remove('hidden');
  }

  const hasBack = useMemo(() => {
    const pages = ['settings/+', 'bills/+'];
    return new RegExp('(' + pages.join('|') + ')', 'gi').test(pathname);
  }, [pathname]);
  return (
    <header
      className={`flex items-center justify-between gap-4 px-6 py-4 bg-white sticky top-0 z-10 ${
        bordered ? 'border-b border-b-gray-200' : ''
      }`}
    >
      {token ? (
        <button
          className="btn-with-icon bg-gray-100 !text-gray-600 !rounded-full !p-2.5 xl:hidden hover:bg-gray-200"
          type="button"
          onClick={openMenu}
        >
          <Icon
            icon="gg:menu"
            width="18"
            height="18"
          />
        </button>
      ) : null}
      <div className="flex-1">
        <div>
          {token ? (
            <CurrentPageTitle />
          ) : (
            <Link to="/">
              <Image
                src="/images/black-logo.svg"
                className="w-full max-w-[7em] object-contain"
              />
            </Link>
          )}
        </div>
      </div>
      <ul className="flex items-center gap-2">
        <li>
          <AppLocalization />
        </li>
        {hasBack ? (
          <li>
            <button
              className="btn-with-icon bg-gray-100 !text-gray-600 !rounded-full !p-2.5 hover:bg-gray-200"
              type="button"
              onClick={() => navigate(-1)}
            >
              <Icon
                icon="mingcute:arrow-left-line"
                width="18"
                height="18"
                className="hidden rtl:block"
              />
              <Icon
                icon="mingcute:arrow-right-line"
                width="18"
                height="18"
                className="block rtl:hidden"
              />
            </button>
          </li>
        ) : null}
      </ul>
    </header>
  );
}
