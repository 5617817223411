import { Icon } from '@iconify/react';
import { GoogleMap, Libraries, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';

const mapStyle = {
  height: '350px',
  width: '100%',
  borderRadius: 8
};

type MapLocation = {
  lat: number | undefined;
  lng: number | undefined;
  address: string | undefined;
};

const libs: Libraries = ['places'];
const defaultLat = 23.885942;
const defaultLng = 45.079162;

export default function Map({
  value,
  onChange
}: {
  value?: MapLocation;
  onChange: (position: MapLocation) => void;
}) {
  useEffect(() => {
    if (!value?.lat && value?.lng) getCurrentLocation();
  }, []);

  const { t } = useTranslation();
  const DEFAULT_ZOOM = 3;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAp0d-qnKPYKuDuIl-_j6z3ivWMAA0GGb0',
    authReferrerPolicy: 'origin',
    libraries: libs
  });

  const [map, setMap] = useState(null);

  const [location, setLocation] = useState<MapLocation>(value as MapLocation);
  const [centerLocation, setCenterLocation] = useState<MapLocation>(value as MapLocation);
  const [defaultLocation, setDefaultLocation] = useState<MapLocation>(value as MapLocation);

  const onLoad = useCallback((map: any) => {
    let result: MapLocation = {
      lat: undefined,
      lng: undefined,
      address: undefined
    };
    const bounds = new window.google.maps.LatLngBounds({
      lat: 23.885942,
      lng: 45.079162
    });

    const autoComplete = new window['google']['maps']['places']['Autocomplete'](
      inputRef.current as HTMLInputElement,
      {
        componentRestrictions: { country: 'sa' },
        types: ['geocode']
      }
    );

    autoComplete?.addListener('place_changed', () => {
      const place: any = autoComplete?.getPlace();

      if (!place.geometry || !place.geometry.location) return;

      if (place.geometry.viewport || place.geometry.location) {
        // do something

        result = {
          lat: place.geometry.location?.lat(),
          lng: place.geometry.location?.lng(),
          address: undefined
        };

        setLocation((loc) => ({
          ...loc,
          ...result
        }));

        setCenterLocation(() => ({
          ...result
        }));

        onChange({
          ...(result as any)
        });
      }
    });

    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handelClickOnMap = () => {};

  const getAddress = (location: MapLocation) => {
    let result: any;
    const geocoder = new window['google']['maps']['Geocoder']();

    geocoder.geocode(
      { location: { lat: location?.lat as any, lng: location?.lng as any } },
      (results, status) => {
        if (status === 'OK') {
          const first = results?.[0];

          result = {
            address: first?.formatted_address || undefined
            // lat: first?.geometry.location?.lat(),
            // lng: first?.geometry.location?.lng()
          };

          setLocation((loc) => ({
            ...location,
            ...result
          }));

          onChange({
            ...location,
            ...result
          });

          (inputRef.current as HTMLInputElement).value = result.address;
        }
      }
    );
  };

  const getCurrentLocation = () => {
    let result: any;
    let message: string;

    const geolocation = window.navigator.geolocation;
    if (!geolocation) return;

    geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        getAddress({ lat: latitude, lng: longitude, address: undefined });
        setCenterLocation(() => ({
          lat: latitude,
          lng: longitude,
          address: undefined
        }));
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = t('location-denied');
            break;
          case error.POSITION_UNAVAILABLE:
            message = t('location-unavailable');
            break;
          case error.TIMEOUT:
            message = t('location-timeout');
            break;
          default:
            message = t('location-error');
        }

        generateAlert(message, 'error');
      }
    );
  };

  return (
    <div>
      {isLoaded ? (
        <div className="space-y-3">
          <div className="relative">
            <input
              ref={inputRef}
              placeholder={t('form.location-address')}
              className="form-input form-outline !pe-12"
              inputMode="numeric"
            />
            <button
              type="button"
              className="w-6 h-6 top-1/2 -translate-y-1/2 flex items-center justify-center absolute right-3 rtl:right-auto rtl:left-3 text-white bg-red-600 rounded-full"
              onClick={getCurrentLocation}
            >
              <Icon
                icon="mingcute:location-fill"
                width="16"
                height="16"
              />
            </button>
          </div>
          <GoogleMap
            onLoad={onLoad}
            center={{
              lat: centerLocation?.lat as any,
              lng: centerLocation?.lng as any
            }}
            zoom={DEFAULT_ZOOM}
            mapContainerStyle={mapStyle}
            onClick={handelClickOnMap}
            onUnmount={onUnmount}
          >
            <Marker
              position={{
                lat: (location?.lat || defaultLocation?.lat || defaultLat) as any,
                lng: (location?.lng || defaultLocation?.lng || defaultLng) as any
              }}
              draggable
              onDrag={(e) => {
                setLocation((loc) => ({
                  ...loc,
                  lat: e.latLng?.lat(),
                  lng: e.latLng?.lng()
                }));

                // onChange(location);
                getAddress(location);
              }}
            />
          </GoogleMap>
        </div>
      ) : (
        <>
          <div className="space-y-3">
            <div className="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
            <div className="w-full h-36 rounded bg-gray-200 animate-pulse"></div>
          </div>
        </>
      )}
    </div>
  );
}
