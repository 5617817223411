import { Icon } from '@iconify/react';
import { InputHTMLAttributes, useMemo, useState } from 'react';

export default function Password({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) {
  const [type, setType] = useState<InputHTMLAttributes<HTMLInputElement>['type']>('password');

  const Button = useMemo(() => {
    return (
      <button
        className="w-12 h-full flex items-center justify-center absolute top-1/2 -translate-y-1/2  appearance-none text-neutral-600"
        type="button"
        onClick={() => {
          if (type === 'password') {
            setType('text');
          } else {
            setType('password');
          }
        }}
      >
        <Icon
          icon={type === 'password' ? 'ri:eye-line' : 'ri:eye-off-fill'}
          width={18}
        />
      </button>
    );
  }, [type]);

  return (
    <div className="relative">
      <input
        type={type}
        className={[className, '!ps-12'].join(' ')}
        placeholder="••••••••"
        {...props}
      />
      {Button}
    </div>
  );
}
