export default function CurrencyFormatter(value: number): string {
  const lang = localStorage.getItem('@lang') || 'ar';

  return new Intl.NumberFormat('en-sa', {
    style: 'currency',
    currency: 'SAR',
    currencyDisplay: 'code',
    currencySign: 'accounting'
  }).format(value);
}
