import { AllHTMLAttributes } from 'react';

export default function Card({ className, ...rest }: AllHTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={
        'bg-white border border-gray-200 p-6 rounded-xl shadow-2xl shadow-gray-600/5 ' + className
      }
      {...rest}
    ></div>
  );
}
