import React, { Fragment, Component, ReactNode, FC } from 'react';
import { FunctionComponent } from 'react';
import BodyItem from './BodyItem';
import LoadTable from './LoadTable';
const BasicTable: FC<{
  size: number;
  HeaderComponent: ReactNode;
  BodyComponent: FunctionComponent<{ item: any; index: string | number }>;
  data: any[];
  isLoading?: boolean;
}> = ({
  size = 1,
  HeaderComponent,
  BodyComponent,
  data = [],
  isLoading,
  ...props
}): JSX.Element => {
  return (
    <Fragment>
      <div {...props}>
        <div className="grid">
          <div className="w-full bg-white rounded-lg divide-y divide-gray-200 shadow shadow-gray-600/5 overflow-x-auto grid">
            <ul className="divide-y divide-gray-100 w-max">
              <li
                className="grid"
                style={{
                  gridTemplateColumns: 'repeat(' + size + ', minmax(0, 1fr))'
                }}
              >
                {HeaderComponent}
              </li>
              {isLoading
                ? Array.from({ length: size })
                    .fill(0)
                    .map((_, index: string | number) => (
                      <li
                        className="grid "
                        key={index}
                        style={{
                          gridTemplateColumns: 'repeat(' + size + ', minmax(0, 1fr))'
                        }}
                      >
                        <LoadTable size={5} />
                      </li>
                    ))
                : null}
              {data?.length ? (
                data?.map((item: any, index: string | number) => (
                  <li
                    className="grid "
                    key={index}
                    style={{
                      gridTemplateColumns: 'repeat(' + size + ', minmax(0, 1fr))'
                    }}
                  >
                    <BodyComponent {...{ item, index }} />
                  </li>
                ))
              ) : (
                <li
                  className="grid "
                  style={{
                    gridTemplateColumns: 'repeat(' + size + ', minmax(0, 1fr))'
                  }}
                >
                  <BodyItem
                    style={{
                      gridColumn: 'span ' + size + ' / span ' + size
                    }}
                    className="text-center"
                  >
                    <p className="text-sm text-gray-600 flex-1">There are no data to display</p>
                  </BodyItem>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BasicTable;
