import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { memo } from 'react';
import { useMemo } from 'react';
import { Fragment } from 'react';
import { FunctionComponent } from 'react';
import { FC } from 'react';
import axiosInstance from '../../helper/AxiosInstance';

interface DisplayQueryInterface {
  result: any;
  isLoading: boolean;
}

interface QueryInterface {
  path: string;
  params?: any;
  RenderResult: FunctionComponent<DisplayQueryInterface>;
  Refresh?: any;
}

const DisplayQueries: FC<QueryInterface> = ({ path, params, RenderResult, Refresh }) => {
  const [result, setResult] = useState<any>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const memoResult = useMemo(() => result, [result]);

  useEffect(() => {
    Fetch();
    Refresh = Fetch;
  }, []);

  const Fetch = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(path, params);
      setResult(data.result);
    } catch (error: any) {
      setError(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <RenderResult
        result={memoResult}
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default memo(DisplayQueries);
