import { ButtonHTMLAttributes } from 'react';

type Variant = 'basic' | 'primary' | 'outline' | 'light' | 'icon';

const classes: Partial<Record<Variant, string>> = {
  basic: 'py-3 px-4 text-sm font-medium rounded flex items-center justify-center gap-3 w-full',
  primary: 'bg-primary text-white',
  outline: 'bg-transparent border',
  light: 'bg-gray-100 text-gray-600',
  icon: '!p-2'
};

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Omit<Variant, 'basic'>;
}

export default function Button({ className, variant = 'light', ...rest }: IProps) {
  return (
    <button
      className={`${classes['basic']} ${classes[variant as Variant]} ${className || ''}`}
      {...rest}
    ></button>
  );
}
