import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ItemList from 'src/components/shared/ItemList';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('contacts', { params }).then(({ data }) => {
        setPagination(() => data.result?.contacts?.pagination || {});
        return data.result?.contacts?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.subject')}</th>
            <th>{t('form.name')}</th>
            <th>{t('form.email')}</th>
            <th>{t('form.mobile')}</th>
            <th>{t('read-at')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.subject || '-'}</td>
                <td>{item.name || '-'}</td>
                <td>{item.email || '-'}</td>
                <td>{item.mobile || '-'}</td>

                <td>
                  <SharedTime date={item.read_at} />
                </td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <ShowMessage
                      item={item}
                      refetch={refetch}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function ShowMessage({ item, refetch }: { item: any; refetch: () => any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  function markMessageAsRead() {
    setDisabled(true);
    prepareRequest({ url: 'contacts/read/' + item.id, method: 'post' }, async () => {
      await refetch?.();
      setVisible(true);
    }).finally(() => setDisabled(false));
  }
  return (
    <>
      <Button
        onClick={() => {
          !item.read_at ? markMessageAsRead() : setVisible(true);
        }}
        disabled={disabled}
        type="button"
      >
        <span>{t('view')}</span>
      </Button>
      <Modal
        title={item.subject}
        visible={visible}
        handleClose={() => setVisible(false)}
      >
        <ul className="divide-y divide-gray-200">
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('form.name')}</p>
            <p className="font-semibold">{item.name || '-'}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('form.email')}</p>
            <p className="font-semibold">{item.email || '-'}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('form.mobile')}</p>
            <p className="font-semibold">{item.mobile || '-'}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('form.subject')}</p>
            <p className="font-semibold">{item.subject || '-'}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('form.desc')}</p>
            <p className="font-semibold">{item.body || '-'}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('read-at')}</p>
            <p className="font-semibold">{moment(item.read_at).format('lll')}</p>
          </ItemList>
          <ItemList className="py-3 space-y-1">
            <p className="text-sm text-gray-500">{t('date')}</p>
            <p className="font-semibold">{moment(item.created_at).format('lll')}</p>
          </ItemList>
        </ul>
      </Modal>
    </>
  );
}
