import React from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
// import '@sweetalert2/themes/dark/dark.css';

interface IProps {
  children?: React.ReactNode;
  callback: () => any;
  options: SweetAlertOptions;
}

export default function ButtonWithCallback({
  callback,
  options,
  ...props
}: IProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  async function handleClick() {
    const { isConfirmed } = await Swal.fire(options);

    if (isConfirmed) return callback();
  }

  return (
    <button
      {...props}
      onClick={handleClick}
    >
    
    </button>
  );
}
