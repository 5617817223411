import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import ButtonWithCallback from './ButtonWithCallback';

export default function DeleteButton({ pathname, refetch }: { pathname: string; refetch: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);

  function deleteItem() {
    setDisabled(true);
    prepareRequest({ url: pathname, method: 'post' }, (data, error) => {
      generateAlert(data.message, 'success');
      refetch();
    }).finally(() => setDisabled(false));
  }

  return (
    <ButtonWithCallback
      callback={deleteItem}
      className="btn-with-icon bg-red-500 text-white !text-sm"
      options={{
        title: t('are-you-sure'),
        text: t('do-process'),
        showCancelButton: true,
        confirmButtonText: t('yes'),
        cancelButtonText: t('cancel')
      }}
      disabled={disabled}
    >
      <Icon
        icon="gg:trash"
        width="18"
        height="18"
      />
      <span>{t('delete')}</span>
    </ButtonWithCallback>
  );
}
