import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import Swal from 'sweetalert2';
import Switcher from './Switcher';

export default function ShowOfferInMain({
  active,
  pathname,
  refetch
}: {
  pathname: string;
  refetch: any;
  active: boolean;
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(active);

  async function changeOfferVisibility() {
    const options = {
      title: t('are-you-sure'),
      text: t('do-process'),
      showCancelButton: true,
      confirmButtonText: t('yes'),
      cancelButtonText: t('cancel')
    };
    const { isConfirmed } = await Swal.fire(options);

    if (!isConfirmed) return;
    setDisabled(true);
    prepareRequest({ url: pathname, method: 'post' }, (data, error) => {
      generateAlert(data.message, 'success');
      refetch();
      setChecked(() => !checked);
    }).finally(() => setDisabled(false));
  }

  return (
    <div className="flex items-center gap-3">
      <Switcher
        checked={checked}
        onChange={changeOfferVisibility}
      />
    </div>
  );
}
