import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { routes } from 'src/helper/routes';
import { RootState } from 'src/store';

export default function usePermissions() {
  const { pathname } = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);


  const hasPermissions = useCallback(
    (item: any) => {
      const { permissions } = user || {};

      if (item.path === '*') return item;
      if (!item.permission) return item;
      if (item.permission instanceof Array)
        return permissions?.some((permission: string) => item.permission.includes(permission));
      return permissions?.includes(item.permission);
    },
    [user, pathname]
  );

  const hasAccess = useCallback(
    (permission: string) => {
      const { permissions } = user || {};
      return permissions?.includes(permission);
    },
    [user, pathname]
  );

  const filterRoutes = useMemo(() => {
    const { user_type } = user || {};

    if (user_type === 'center_owner') return routes;

    return routes.filter((route) => hasPermissions(route));
  }, [user, pathname]);

  return { filterRoutes, hasPermissions, hasAccess };
}
