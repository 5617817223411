import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Modal from 'src/components/shared/Modal';
import Select from 'src/components/shared/Select';
import SharedTime from 'src/components/shared/SharedTime';
import UpdateIsFeatured from 'src/components/shared/UpdateIsFeatured';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function index() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('cities', { params }).then(({ data }) => {
        setPagination(() => data.result?.cities?.pagination || {});
        return data.result?.cities?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.name')}</th>
            <th>{t('form.alt-name')}</th>
            <th>{t('form.region')}</th>
            <th>{t('featured')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th></th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>{item.name || '-'}</td>
                <td>{item.alt_name || '-'}</td>

                <td>
                  {item.region?.name} - {item.region?.alt_name}
                </td>
                <td>
                  <UpdateIsFeatured
                    pathname={'cities/update_is_featured/' + item.id}
                    refetch={refetch}
                    active={!!item.is_featured}
                  />
                </td>
                <td>
                  <ChangeStatus
                    pathname={'cities/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>

                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      refetch={refetch}
                      item={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        Actions={() => (
          <>
            <AddForm refetch={refetch} />
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function AddForm({ refetch }: { refetch: () => any }) {
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [regions, setRegions] = useState<any[]>([]);

  useEffect(() => {
    if (!visible) return;
    getRegions();
  }, [visible]);

  function getRegions() {
    prepareRequest({ url: 'regions', params: { is_active: 1 } }, (data) =>
      setRegions(() => data.result?.regions || [])
    );
  }

  const {
    formik: { handleChange, values, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: {},
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'cities/add',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('add-new')}</span>
      </Button>
      <Modal
        size="max-w-screen-sm"
        visible={visible}
        handleClose={() => setVisible(false)}
        title={t('add-new')}
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
            />

            <p className="form-error">{errors['name']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              name="alt_name"
              id="alt_name"
              value={values.alt_name}
              onChange={handleChange}
            />

            <p className="form-error">{errors['alt_name']}</p>
          </div>

          <div className="form-group">
            <label className="form-label">{t('form.region')}</label>
            <Select
              type={'single'}
              options={regions}
              value={values.region_id}
              onSelect={function (value: any): void {
                setFieldValue('region_id', value);
              }}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}

function UpdateForm({ refetch, item }: { refetch: () => any; item: any }) {
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [regions, setRegions] = useState<any[]>([]);

  useEffect(() => {
    if (!visible) return;
    getRegions();
  }, [visible, item]);

  function getRegions() {
    prepareRequest({ url: 'regions', params: { is_active: 1 } }, (data) =>
      setRegions(() => data.result?.regions || [])
    );
  }

  const {
    formik: { handleChange, values, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: {
      ...item,
      region_id: item.region?.id
    },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'cities/update',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          refetch?.();
          setVisible(false);
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        size="max-w-screen-sm"
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${item.name} - ${item.alt_name}`}
      >
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
        >
          <div className="form-group">
            <p className="form-label">{t('form.name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
            />

            <p className="form-error">{errors['name']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-name')}</p>
            <input
              type="text"
              className="form-input form-outline"
              name="alt_name"
              id="alt_name"
              value={values.alt_name}
              onChange={handleChange}
            />

            <p className="form-error">{errors['alt_name']}</p>
          </div>

          <div className="form-group">
            <label className="form-label">{t('form.region')}</label>
            <Select
              type={'single'}
              options={regions}
              value={values.region_id}
              onSelect={function (value: any): void {
                setFieldValue('region_id', value);
              }}
              optionTxt={i18n.language == 'ar' ? 'alt_name' : 'name'}
              optionValue={'id'}
            />

            {errors?.region_id ? <span className="form-error">{errors?.region_id}</span> : null}
          </div>

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
          >
            <span>{t('save-changes')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
