import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import Card from 'src/components/shared/Card';
import Switcher from 'src/components/shared/Switcher';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function newNotification() {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams({ page: '1' });
  const [pagination, setPagination] = useState<any>({});
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('users', { params }).then(({ data }) => {
        setPagination(() => data.result?.users?.pagination || {});
        return data.result?.users?.data || [];
      })
  });
  const [disabled, setDisabled] = useState<boolean>(false);
  const types = [
    {
      title: 'all',
      content: 'all-content',
      key: 'all'
    },
    {
      title: 'custom',
      content: 'custom-content',
      key: 'custom'
    }
  ];
  const [errors, setErrors] = useState<any>({});
  const {
    formik: { values, handleChange, handleSubmit, setFieldValue }
  } = useForm({
    initialValues: { to_type: 'custom', user_ids: [] },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      setErrors({});
      prepareRequest(
        {
          url: 'app_notifications/send',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(() => error);
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });
  return (
    <form
      className="p-6 space-y-4"
      onSubmit={handleSubmit}
    >
      <Card className="space-y-4">
        <div className="form-group">
          <p className="form-label">{t('form.title')}</p>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input"
            placeholder="..."
            value={values.title}
            onChange={handleChange}
          />
          <p className="form-error">{errors['title']}</p>
        </div>
        <div className="form-group">
          <p className="form-label">{t('form.desc')}</p>
          <textarea
            name="body"
            id="body"
            className="form-textarea"
            placeholder="..."
            value={values.body}
            onChange={handleChange}
          ></textarea>
          <p className="form-error">{errors['body']}</p>
        </div>
        <div className="form-group">
          <ul className="space-y-3">
            {types.map((type: any) => (
              <li key={type.key}>
                <div className="flex items-center gap-4">
                  <input
                    type="radio"
                    name="to_type"
                    id={type.key}
                    onChange={handleChange}
                    value={type.key}
                    className="form-radio shrink-0"
                    checked={values.to_type === type.key}
                  />
                  <label
                    htmlFor={type.key}
                    className="flex-1 cursor-pointer block"
                  >
                    <p className="text-sm font-semibold">{t(type.title)}</p>
                    <p className="text-sm text-gray-500">{t(type.content)}</p>
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <p className="form-error">{errors['to_type']}</p>
        </div>
      </Card>
      {values.to_type === 'custom' ? (
        <>
          <p className="text-sm text-gray-600 font-semibold">{t('users')}</p>
          <Table
            RenderHead={() => (
              <tr>
                <th></th>
                <th>{t('form.name')}</th>
                <th>{t('form.email')}</th>
                <th>{t('form.mobile')}</th>
              </tr>
            )}
            RenderBody={() => (
              <>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      <Switcher
                        checked={values.user_ids?.includes(item.id)}
                        onChange={(checked) =>
                          setFieldValue(
                            'user_ids',
                            checked
                              ? [...values.user_ids, item.id]
                              : values.user_ids?.filter((user: number) => user !== item.id)
                          )
                        }
                      />
                    </td>
                    <td>{item.name || '-'}</td>
                    <td>{item.email || '-'}</td>
                    <td>{item.mobile || '-'}</td>
                  </tr>
                ))}
              </>
            )}
            loading={isLoading}
            isEmpty={!data?.length}
            pagination={pagination}
            onNextClick={() => {
              setParams((param) => {
                param.set('page', (Number(param.get('page')) + 1).toString());
                return param;
              });
              refetch();
            }}
            onPreviousClick={() => {
              setParams((param) => {
                param.set('page', (Number(param.get('page')) - 1).toString());
                return param;
              });
              refetch();
            }}
            searchProps={{
              onChange: (ev: ChangeEvent<HTMLInputElement>) => {
                setParams((param) => {
                  param.set('search_key', ev.target.value);
                  return param;
                });
                refetch();
              }
            }}
          />
        </>
      ) : null}
      <Button
        variant="primary"
        type="submit"
        disabled={disabled}
      >
        <span>{t('submit')}</span>
      </Button>
    </form>
  );
}
