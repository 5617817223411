import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions from 'src/hooks/usePermissions';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../../components/shared/MiniDashboardSingleCard';

const Settings: FC = () => {
  const { t } = useTranslation();
  const cards: ItemInterface[] = [
    {
      icon: 'ri:settings-6-line',
      color: 'text-gray-500',
      value: t('pages.advanced-settings'),
      permission: 'advanced-settings',
      url: '/settings/advanced'
    },
    {
      icon: 'ep:price-tag',
      color: 'text-gray-500',
      value: t('pages.packages'),
      permission: 'packages',
      url: '/settings/packages'
    },

    {
      icon: 'ph:stack',
      color: 'text-gray-500',
      value: t('pages.services'),
      permission: 'services',
      url: '/settings/services'
    },
    {
      icon: 'icon-park-outline:list',
      color: 'text-gray-500',
      value: t('pages.features'),
      permission: 'features',
      url: '/settings/features'
    },
    {
      icon: 'fe:layer',
      color: 'text-gray-500',
      value: t('pages.categories'),
      permission: 'categories',
      url: '/settings/categories'
    },

    {
      icon: 'fluent:location-24-regular',
      color: 'text-gray-500',
      value: t('pages.regions'),
      permission: 'regions',
      url: '/settings/regions'
    },
    {
      icon: 'fluent:location-24-regular',
      color: 'text-gray-500',
      value: t('pages.cities'),
      permission: 'cities',
      url: '/settings/cities'
    },
    {
      icon: 'fluent:location-24-regular',
      color: 'text-gray-500',
      value: t('pages.districts'),
      permission: 'districts',
      url: '/settings/districts'
    },

    {
      icon: 'fluent-mdl2:laptop-secure',
      color: 'text-gray-500',
      value: t('pages.roles'),
      permission: 'roles',
      url: '/settings/roles'
    },
    {
      icon: 'ic:round-star-outline',
      color: 'text-gray-500',
      value: t('pages.rate-types'),
      permission: 'rate-types',
      url: '/settings/rate-types'
    },
    {
      icon: 'carbon:pedestrian-family',
      color: 'text-gray-500',
      value: t('pages.relative-relations'),
      permission: 'relative-relations',
      url: '/settings/relative-types'
    },
    {
      icon: 'eva:email-outline',
      color: 'text-gray-500',
      value: t('pages.email-templates'),
      permission: 'email-templates',
      url: '/settings/email-templates'
    },
    {
      icon: 'lucide:layout',
      color: 'text-gray-500',
      value: t('pages.website-settings'),
      permission: 'website-settings',
      url: 'https://meaad.sa/wp-login.php'
    }
  ];
  const { hasPermissions } = usePermissions();

  return (
    <Fragment>
      <div className="p-6 space-y-4">
        <div className="grid grid-wrapper gap-4">
          {cards.filter(hasPermissions).map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
