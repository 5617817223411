import BasicTable from '../../components/shared/tables/BasicTable';
import React, { FC } from 'react';
import {
  MiniDashboardSingleCard,
  ItemInterface
} from '../../components/shared/MiniDashboardSingleCard';
import { Fragment } from 'react';
import HeadItem from '../../components/shared/tables/HeadItem';
import BodyItem from '../../components/shared/tables/BodyItem';
import DisplayQueries from '../../components/shared/DisplayQueries';
import LoadTable from '../../components/shared/tables/LoadTable';
import { Icon } from '@iconify/react';
const cards: ItemInterface[] = [
  {
    icon: 'healthicons:money-bag-outline',
    color: 'text-teal-500',
    title: 'Invoices paid',
    value: 0
  },
  {
    icon: 'majesticons:money-plus-line',
    color: 'text-red-500',
    title: 'Invoices due',
    value: 0
  }
];
const Invoices: FC = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <p className="text-lg font-semibold text-gray-500">Summary</p>
        <div className="grid grid-wrapper gap-4">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard key={i} item={item} />
          ))}
        </div>
        <div className="flex items-center justify-between flex-wrap">
          <p className="text-lg font-semibold text-gray-500 shrink-0">Invoices table</p>
          <div className="inline-flex gap-2 shrink-0 flex-wrap">
            <button className="btn-with-icon bg-primary shrink-0">
              <span>
                <Icon icon="ri:file-excel-2-line" width={18} />
              </span>
              <span>Export EXCEL</span>
            </button>
          </div>
        </div>
        <DisplayQueries
          path="users"
          RenderResult={({ result, isLoading }) => (
            <BasicTable
              size={7}
              data={result}
              HeaderComponent={
                <Fragment>
                  <HeadItem>Invoice no.</HeadItem>
                  <HeadItem>Paid date</HeadItem>
                  <HeadItem>Due date</HeadItem>
                  <HeadItem>Period</HeadItem>
                  <HeadItem>Center</HeadItem>
                  <HeadItem>Status</HeadItem>
                  <HeadItem>Action</HeadItem>
                </Fragment>
              }
              BodyComponent={() => (
                <Fragment>
                  {isLoading ? (
                    <LoadTable size={7} />
                  ) : (
                    <Fragment>
                      <BodyItem>#{new Date().getTime()}</BodyItem>
                      <BodyItem>{new Date().toISOString().substring(0, 10)}</BodyItem>
                      <BodyItem>{new Date().toISOString().substring(0, 10)}</BodyItem>
                      <BodyItem>3 Months</BodyItem>
                      <BodyItem>Center name</BodyItem>
                      <BodyItem>
                        <p className="inline-flex gap-2 items-center">
                          <span className="shrink-0 inline w-2 h-2 rounded-full bg-sky-500"></span>
                          <span>Active</span>
                        </p>
                      </BodyItem>
                      <BodyItem></BodyItem>
                    </Fragment>
                  )}
                </Fragment>
              )}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default Invoices;
