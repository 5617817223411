import { createSlice } from '@reduxjs/toolkit';

interface State {
  title: string | undefined;
}

const initialState: State = {
  title: undefined
};

const root = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setTitle(state, action) {
      state.title = action.payload;
    }
  }
});

export const { setTitle } = root.actions;

export default root.reducer;
