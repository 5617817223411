import { Menu, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

type Lang = 'ar' | 'en';

type LangItem = {
  language: Lang;
  flag: string;
};

const languages: LangItem[] = [
  {
    language: 'ar',
    flag: 'circle-flags:sa'
  },
  {
    language: 'en',
    flag: 'circle-flags:us'
  }
];

export default function AppLocalization() {
  const { t, i18n } = useTranslation();
  return (
    <Menu
      as="div"
      className="relative"
    >
      <Menu.Button className="flex items-center gap-3 text-gray-600 text-sm transition hover:bg-gray-200 py-2 px-3 rounded-full bg-gray-100">
        <Icon
          icon="material-symbols:language"
          width="18"
          height="18"
        />
        <span>{t(`langs.${i18n.language}`)}</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right rtl:right-auto rtl:left-0 rtl:origin-top-left divide-y divide-gray-100 bg-white overflow-hidden rounded border border-gray-100 shadow-2xl shadow-gray-800/10 transition flex flex-col">
          {languages.map((lang) => (
            <Menu.Item key={lang.language}>
              <LanguageItem language={lang} />
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function LanguageItem({ language }: { language: LangItem }) {
  const { t, i18n } = useTranslation();

  function changeLanguage() {
    i18n.changeLanguage(language.language);
    localStorage.setItem('@lang', language.language);
  }

  return (
    <button
      className={`py-2 px-3 flex items-center gap-2 text-sm transition ${
        i18n.language == language.language
          ? 'text-white bg-primary'
          : 'text-gray-600 hover:bg-gray-50'
      }`}
      onClick={changeLanguage}
    >
      <Icon
        icon={language.flag}
        width="16"
        height="16"
      />
      <span>{t('langs.' + language.language)}</span>
    </button>
  );
}
