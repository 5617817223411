import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { Fragment } from 'react';

function Modal({
  visible,
  handleClose,
  title,
  children,

  size
}: {
  visible?: boolean;
  handleClose?: any;
  title?: string;
  children?: React.ReactNode;
  size?: string;
}) {
  return (
    <>
      <Transition
        appear
        show={visible}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-20"
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={[
                    'w-full transform rounded-2xl bg-white p-6 align-middle text-start shadow-xl transition-all space-y-6',
                    size ? size : 'max-w-xl'
                  ].join(' ')}
                >
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex-1">
                      {title ? (
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {title}
                        </Dialog.Title>
                      ) : null}
                    </div>
                    <button
                      className="w-8 h-8 shrink-0 flex items-center justify-center rounded-full text-gray-600"
                      type="button"
                      onClick={handleClose}
                    >
                      <Icon
                        icon="material-symbols:close"
                        width="20"
                        height="20"
                      />
                    </button>
                  </div>
                  <div>{React.isValidElement(children) ? children : null}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default React.memo(Modal);
