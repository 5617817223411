import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';

export default function id() {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axiosInstance.get('bills/' + id);
        setResponse(data.result.bill);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('form.doctor')}</th>
              <th>{t('form.patient')}</th>
              <th>{t('form.center')}</th>
              <th>{t('form.advance-payment')}</th>
              <th>{t('subtotal')}</th>
              <th>{t('total')}</th>
              <th>{t('payment-status')}</th>
              <th>{t('status')}</th>
              <th>{t('form.booking-time')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {response?.bookings?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>{item?.doctor?.name || '-'}</td>
                    <td>{item?.patient?.name || '-'}</td>
                    <td>{item?.center?.name || '-'}</td>
                    {/* <td >
                      {item?.user?.name || '-'}
                    </td> */}
                    <td>{CurrencyFormatter(item?.advance_payment || 0)}</td>
                    <td>{CurrencyFormatter(item?.sub_total || 0)}</td>
                    <td>{CurrencyFormatter(item?.total || 0)}</td>

                    <td>{t(`${item.is_paid ? 'statuses.paid' : 'statuses.not-paid'}`)}</td>
                    <td>{t(`statuses.${item.status}`)}</td>

                    <td>
                      <SharedTime date={item.booking_time} />
                    </td>
                  </tr>
                );
              })}
            </>
          );
        }}
        isEmpty={!response?.bookings?.length}
      />
    );
  }, [response]);

  return (
    <div>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div className="p-6 space-y-4">
          {/* <div className="rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5 p-6">
          </div> */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-px p-px bg-gray-200">
            <div className="p-4 w-full bg-white space-y-1 sm:col-span-2">
              <p className="text-sm text-gray-500">{t('form.center')}</p>
              <p className="text-base font-semibold text-title">
                {response?.center?.name || 'N/A'}
              </p>
            </div>
            <div className="p-4 w-full bg-white space-y-1 sm:col-span-2">
              <p className="text-sm text-gray-500">{t('amount')}</p>
              <p className="text-base font-semibold text-title">
                {CurrencyFormatter(response?.amount || 0)}
              </p>
            </div>
            <div className="p-4 w-full bg-white space-y-1">
              <p className="text-sm text-gray-500">{t('deadline-due-date')}</p>
              <SharedTime date={response?.deadline_due_date} />
            </div>
            <div className="p-4 w-full bg-white space-y-1">
              <p className="text-sm text-gray-500">{t('paid-date')}</p>
              {response?.paid_at ? <SharedTime date={response?.paid_at} /> : '-'}
            </div>

            <div className="p-4 w-full bg-white space-y-1 sm:col-span-2">
              <p className="text-sm text-gray-500">{t('date')}</p>
              <SharedTime date={response?.created_at} />
            </div>
          </div>
          {MEMO_TABLE}
        </div>
      )}
    </div>
  );
}
