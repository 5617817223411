import { AllHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export default function Pagination({
  pagination,
  onPreviousClick,
  onNextClick
}: {
  pagination?: any;
  onNextClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  onPreviousClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
}) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-4 flex-wrap p-4 bg-gray-100 !mt-0">
      <p className="text-gray-500 flex-1 shrink-0">
        {t('show')} {pagination?.page || pagination?.current_page || 0} {t('to')}{' '}
        {pagination?.total_pages || 0} {t('of')} {pagination?.total || 0} {t('results')}
      </p>
      <div className="inline-flex flex-wrap gap-2">
        <button
          className="btn-with-icon outline-btn shrink-0"
          type="button"
          disabled={(pagination?.page || pagination?.current_page) <= 1}
          onClick={onPreviousClick}
        >
          <span>{t('prev')}</span>
        </button>

        <button
          className="btn-with-icon outline-btn shrink-0"
          type="button"
          disabled={
            (pagination?.page || pagination?.current_page) === pagination?.total_pages ||
            !pagination?.total_pages
          }
          onClick={onNextClick}
        >
          <span>{t('next')}</span>
        </button>
      </div>
    </div>
  );
}
