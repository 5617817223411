import React from 'react';
import { ReactNode } from 'react';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { MoveToTop } from '../../../animations';
import { Fragment } from 'react';

const LoadTable: FC<{ size?: number }> = ({ size = 1 }) => {
  return (
    <Fragment>
      {Array.from({ length: size })
        .fill(0)
        .map((_, index: number | string) => (
          <motion.div
            animate="visible"
            initial="hidden"
            variants={MoveToTop}
            className="inline-flex px-6 py-5 w-full relative whitespace-nowrap"
            key={index}>
            <span className="w-full max-w-xs bg-gray-200 rounded p-2 animate-pulse"></span>
          </motion.div>
        ))}
    </Fragment>
  );
};

export default LoadTable;
