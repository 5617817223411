import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from 'src/layouts/Footer';
import Header from 'src/layouts/Header';
import Image from '../shared/Image';

export default function AuthWrapper({ children }: { children?: ReactNode }) {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen w-full flex bg-white">
      <div className="w-2/6 bg-primary hidden py-20 px-8 lg:flex flex-col">
        <Image
          src="/images/auth-image.png"
          className="w-full m-auto max-w-md object-contain"
        />
        <div className="mt-6 space-y-4 text-center">
          <p className="text-3xl font-bold text-white">{t('auth.title')}</p>
          <p className="text-white">{t('auth.content')}</p>
          <Link
            to="https://meaad.sa?source=portal"
            target="_blank"
            className="w-full max-w-xs py-3 px-6 rounded bg-transparent text-white flex items-center justify-center text-sm font-medium border-2 border-white transition hover:bg-white hover:text-primary m-auto"
          >
            <span>{t('auth.btn')}</span>
          </Link>
        </div>
      </div>
      <div className="flex-1 lg:w-4/6 flex flex-col min-h-screen">
        <Header />

        <div className="flex-1 flex items-center justify-center py-20 px-8">{children}</div>
        <Footer />
      </div>
    </div>
  );
}
