import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Button from 'src/components/shared/Button';
import Password from 'src/components/shared/Password';
import generateAlert from 'src/helper/generateAlert';
import { storeResetEmail } from 'src/reducers/auth';
import { RootState } from 'src/store';
import prepareRequest from '../../helper/prepareRequest';

interface AuthInterface {
  email_mobile?: string;
}

const FormBody: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reset_password_email } = useSelector((state: RootState) => state.auth);

  const globalValues = {} satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [verified, setVerified] = React.useState<boolean>(false);

  useEffect(() => {
    if (!reset_password_email) navigate('/account/login', { replace: true });
  }, [reset_password_email]);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, helper) => {
      setDisabled(true);
      prepareRequest(
        {
          url: 'reset_password/save',
          method: 'post',
          data: { ...values, email_mobile: reset_password_email }
        },
        (data, error) => {
          if (error) return setErrors(() => error);

          // helper.resetForm();
          generateAlert(data.message, 'success');
          if (!!data.result?.need_password) {
            setVerified(true);
          } else {
            dispatch(storeResetEmail({ email: null }));
            navigate('/account/login', { replace: true });
          }
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-3"
      onSubmit={handleSubmit}
    >
      <div className="mb-6 space-y-4 text-center">
        <p className="text-3xl font-bold">
          {verified ? t('auth.reset-password.title') : t('auth.verify-code.title')}
        </p>
        <p className="text-gray-500">
          {verified
            ? t('auth.reset-password.content')
            : t('auth.verify-code.content').replace('{{EMAIL}}', reset_password_email as string)}
        </p>
      </div>
      {verified ? (
        <>
          <div className="form-group">
            <label
              htmlFor="new-password"
              className="form-label"
            >
              {t('form.new-password')}
            </label>
            <Password
              name="password"
              id="new-password"
              placeholder="••••••"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('password', target.value)
              }
            />
            {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
          </div>
          <div className="form-group">
            <label
              htmlFor="confirm-password"
              className="form-label"
            >
              {t('form.confirm-password')}
            </label>
            <Password
              name="password_confirmation"
              id="confirm-password"
              placeholder="••••••"
              className="form-input form-outline !bg-gray-50"
              autoComplete="off"
              autoCapitalize="false"
              autoCorrect="true"
              onChange={({ target }: { target: HTMLInputElement }) =>
                setFieldValue('password_confirmation', target.value)
              }
            />
            {errors?.password_confirmation ? (
              <span className="form-error">{errors?.password_confirmation}</span>
            ) : null}
          </div>
        </>
      ) : (
        <div className="form-group">
          <label
            htmlFor="verify-code"
            className="form-label"
          >
            {t('form.verify-code')}
          </label>
          <input
            name="text"
            id="code"
            placeholder="••••••"
            className="form-input form-outline !bg-gray-50"
            autoComplete="off"
            autoCapitalize="false"
            autoCorrect="true"
            onChange={({ target }: { target: HTMLInputElement }) =>
              setFieldValue('code', target.value)
            }
          />
          {errors?.code ? <span className="form-error">{errors?.code}</span> : null}
        </div>
      )}

      <div className="!mt-8 space-y-2">
        <Button
          variant="primary"
          type="submit"
          disabled={disabled}
        >
          <span>{t('save-changes')}</span>
        </Button>
        <p className="flex items-center gap-4 !my-4">
          <span className="flex-1 h-px bg-gray-200"></span>
          <span className="text-sm font-medium text-gray-600 block">{t('or')}</span>
          <span className="flex-1 h-px bg-gray-200"></span>
        </p>
        <p className="text-gray-600 text-center text-sm">
          <Link
            className="font-medium text-primary hover:underline transition"
            to="/account/login"
          >
            <span>{t('sign-in')}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

const ResetPassword: React.FC = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="max-w-screen-sm m-auto w-full">
        <FormBody />
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
