import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteObject, useLocation, useNavigate } from 'react-router-dom';
import { roles } from 'src/components/shared/roles/AddForm';
import axiosInstance from 'src/helper/AxiosInstance';
import { authenticationRoutes } from 'src/helper/routes';
import { saveUserInfo, storeToken } from 'src/reducers/auth';

function useAuth(): boolean {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const stored_token = localStorage.getItem('@token');

      if (!stored_token) {
        dispatch(storeToken(null));
        setIsLoading(false);
        if (/account\/(reset|forgot|login|register)/gi.test(pathname)) return;
        navigate('/account/login', {
          replace: true
        });
        return;
      }

      await GetUserInfo(stored_token);
    })();
  }, []);

  async function GetUserInfo(token?: string) {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get('profile');
      const profile = data?.result?.profile || {};
      const type = profile?.user_type;
      const permissions = data?.result?.permissions;

      dispatch(storeToken(token));
      dispatch(
        saveUserInfo({
          ...profile,
          permissions
        })
      );

      if (authenticationRoutes.some((route: RouteObject) => [route.path].includes(pathname))) {
        return navigate('/', {
          replace: true
        });
      }
      navigate(pathname, {
        replace: true
      });
    } catch (error) {
      dispatch(storeToken(null));
      localStorage.removeItem('@token');
      navigate('/account/login', { replace: true });
    } finally {
      setIsLoading(false);
    }
  }
  return isLoading;
}

export function getPermissions() {
  // return new Array().concat(...roles.map((role) => role.access.map((e) => `${e} ${role.key}`)));
  return roles.map((role) => role.key);
  // return ['roles', 'bookings'];
}

export default useAuth;
