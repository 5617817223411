import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/shared/tables/Table';
import prepareRequest from 'src/helper/prepareRequest';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../components/shared/MiniDashboardSingleCard';

const Home: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [cards, setCards] = useState<ItemInterface[]>([
    {
      icon: 'ion:wallet-outline',
      color: 'text-teal-500',
      title: t('pages.incomes'),
      key: 'income',
      value: 0
    },

    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('pages.bookings'),
      key: 'bookings_count',
      value: 0
    },
    {
      icon: 'ri:hospital-line',
      color: 'text-sky-500',
      title: t('pages.centers'),
      key: 'centers_count',
      value: 0
    },
    {
      icon: 'fa6-solid:user-doctor',
      color: 'text-teal-500',
      title: t('pages.doctors'),
      key: 'doctors_count',
      value: 0
    }
  ]);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  useEffect(() => {
    getDashboard();
  }, []);

  function getDashboard() {
    prepareRequest(
      {
        url: 'dashboard'
      },
      (data, error) => {
        const summary = data.result?.summary || [];
        setCards((items: ItemInterface[]) => {
          return items.map((item) => {
            if (Object.getPrototypeOf.call(summary, item.key)) {
              const value = summary[item.key as string];
              item.value = value;
              return item;
            }
            return item;
          });
        });
        setSubscriptions(() => data.result?.recent_subscriptions || []);
      }
    );
  }

  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="grid grid-wrapper gap-4">
          {cards.map((item: ItemInterface) => (
            <MiniDashboardSingleCard
              key={item.key}
              item={item}
            />
          ))}
        </div>
        <p className="text-sm text-gray-600">{t('recent-subscriptions')}</p>
        <Table
          RenderHead={() => (
            <tr>
              <th>{t('form.center-name')}</th>
              <th>{t('form.name')}</th>
              <th>{t('form.mobile')}</th>
              <th>{t('form.email')}</th>
            </tr>
          )}
          RenderBody={() => (
            <>
              {subscriptions.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.name || '-'}</td>
                  <td>{item.owner?.name || '-'}</td>
                  <td>{item.owner?.mobile || '-'}</td>
                  <td>{item.owner?.email || '-'}</td>
                </tr>
              ))}
            </>
          )}
          isEmpty={!subscriptions.length}
        />
      </div>
    </React.Fragment>
  );
};

export default Home;
