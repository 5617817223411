import { useQuery } from '@tanstack/react-query';
import { serialize } from 'object-to-formdata';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/shared/Button';
import ChangeStatus from 'src/components/shared/ChangeStatus';
import Image from 'src/components/shared/Image';
import InputFile from 'src/components/shared/InputFile';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function EmailTemplates() {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<any>({});
  const [params, setParams] = useSearchParams({ page: '1' });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['page', 'search'],
    queryFn: () =>
      axiosInstance.get('email_templates', { params }).then(({ data }) => {
        setPagination(() => data.result?.email_templates?.pagination || {});
        return data.result?.email_templates?.data || [];
      })
  });
  return (
    <div className="p-6">
      <Table
        RenderHead={() => (
          <tr>
            <th>{t('form.image')}</th>
            <th>{t('form.title')}</th>
            <th>{t('form.alt-title')}</th>
            <th>{t('status')}</th>
            <th>{t('date')}</th>
            <th>{t('actions')}</th>
          </tr>
        )}
        RenderBody={() => (
          <>
            {data?.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <Image
                    src={item.image}
                    alt={item.name}
                    className="w-10 h-10 object-contain"
                  />
                </td>
                <td>{item.title || '-'}</td>
                <td>{item.alt_title || '-'}</td>
                <td>
                  <ChangeStatus
                    pathname={'email_templates/update_is_active/' + item.id}
                    refetch={refetch}
                    active={!!item.is_active}
                  />
                </td>

                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td>
                  <div className="actions">
                    <UpdateForm
                      refetch={refetch}
                      item={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
        loading={isLoading}
        isEmpty={!data?.length}
        pagination={pagination}
        onNextClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) + 1).toString());
            return param;
          });
          refetch();
        }}
        onPreviousClick={() => {
          setParams((param) => {
            param.set('page', (Number(param.get('page')) - 1).toString());
            return param;
          });
          refetch();
        }}
        searchProps={{
          onChange: (ev: ChangeEvent<HTMLInputElement>) => {
            setParams((param) => {
              param.set('search_key', ev.target.value);
              return param;
            });
            refetch();
          }
        }}
      />
    </div>
  );
}

function UpdateForm({ refetch, item }: { refetch: () => any; item: any }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    formik: { handleChange, handleSubmit, setFieldValue, values }
  } = useForm({
    initialValues: { ...item },
    submitHandler(values, formikHelpers) {
      setDisabled(true);
      const fd = serialize(values);
      if (!(values.image instanceof File)) fd.delete('image');

      prepareRequest({ url: 'email_templates/update', method: 'post', data: fd }, (data, error) => {
        if (error) return setErrors(() => error);
        generateAlert(data.message, 'success');
        refetch();
        setVisible(false);
        formikHelpers.resetForm();
      }).finally(() => setDisabled(false));
    }
  });

  return (
    <>
      <Button
        type="button"
        onClick={() => setVisible(true)}
        className="max-w-fit"
      >
        <span>{t('edit')}</span>
      </Button>
      <Modal
        visible={visible}
        handleClose={() => setVisible(false)}
        title={`${t('edit')} - (${item.title})`}
      >
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <p className="form-label">{t('form.image')}</p>
            <InputFile
              accept="image/*"
              onValueChange={(value) => setFieldValue('image', value)}
            />
            <p className="form-error">{errors['image']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.title')}</p>
            <input
              type="text"
              name="title"
              className="form-input"
              id="title"
              value={values.title}
              onChange={handleChange}
            />
            <p className="form-error">{errors['title']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-title')}</p>
            <input
              type="text"
              name="alt_title"
              className="form-input"
              id="alt_title"
              value={values.alt_title}
              onChange={handleChange}
            />
            <p className="form-error">{errors['alt_title']}</p>
          </div>

          <div className="form-group">
            <p className="form-label">{t('form.desc')}</p>
            <textarea
              name="body"
              className="form-input"
              id="body"
              value={values.body}
              onChange={handleChange}
            ></textarea>
            <p className="form-error">{errors['body']}</p>
          </div>
          <div className="form-group">
            <p className="form-label">{t('form.alt-desc')}</p>
            <textarea
              name="alt_body"
              className="form-input"
              id="alt_body"
              value={values.alt_body}
              onChange={handleChange}
            ></textarea>
            <p className="form-error">{errors['alt_body']}</p>
          </div>
          <Button
            type="submit"
            disabled={disabled}
            variant="primary"
          >
            <span>{t('submit')}</span>
          </Button>
        </form>
      </Modal>
    </>
  );
}
