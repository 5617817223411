import { Fragment } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoadingComponent from './components/shared/LoadingComponent';
import { authenticationRoutes } from './helper/routes';
import ScrollToTop from './hooks/ScrollToTop';
import useAuth from './hooks/useAuth';
import './i18n';
import HeaderLayout from './layouts/HeaderLayout';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import usePermissions from './hooks/usePermissions';
import { RootState } from './store';

const queryClient = new QueryClient();

function AppWrapper() {
  const isLoading = useAuth();
  const { token } = useSelector((state: RootState) => state.auth);

  const { filterRoutes } = usePermissions();

  if (isLoading) return <LoadingComponent />;

  return (
    <Fragment>
      {token ? (
        <HeaderLayout>
          <Routes>
            {filterRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                element={route.element}
                path={route.path}
              ></Route>
            ))}
          </Routes>
        </HeaderLayout>
      ) : (
        <Fragment>
          <Routes>
            {authenticationRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                {...route}
              ></Route>
            ))}
          </Routes>
        </Fragment>
      )}
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AnimatePresence>
          <AppWrapper />
          <ScrollToTop />
        </AnimatePresence>
      </QueryClientProvider>
    </Router>
  );
}

export default App;

