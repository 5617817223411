import { RouteObject } from 'react-router-dom';
import {
  ActivityLogs,
  Admins,
  AdvancedSettings,
  Categories,
  Centers,
  Cities,
  Districts,
  EmailTemplates,
  ErrorPage,
  Features,
  ForgotPassword,
  Home,
  Invoices,
  Login,
  Messages,
  NewNotification,
  Notifications,
  NotificationTemplates,
  Offers,
  Packages,
  Profile,
  RateTypes,
  Regions,
  RelativeTypes,
  ResetPassword,
  Roles,
  Services,
  Settings,
  SingleMessage
} from 'src/screens';
import { BillDetails, Bills, Bookings } from '../screens';

type Route = RouteObject & {
  permission?: string | string[];
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },

  {
    path: '/account/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/account/forgot-password',
    element: <ForgotPassword />
  },
  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },
  {
    path: '/centers',
    element: <Centers />,
    permission: 'centers'
  },

  {
    path: '/invoices',
    element: <Invoices />,
    permission: 'invoices'
  },
  {
    path: '/offers',
    element: <Offers />,
    permission: 'offers'
  },

  {
    path: '/notifications',
    element: <Notifications />,
    permission: 'notifications'
  },
  {
    path: '/notifications/new',
    element: <NewNotification />,
    permission: 'notifications'
  },
  {
    path: '/notifications/templates',
    element: <NotificationTemplates />,
    permission: 'notifications'
  },
  {
    path: '/messages',
    element: <Messages />,
    permission: 'messages'
  },
  {
    path: '/logs',
    element: <ActivityLogs />,
    permission: 'logs'
  },
  {
    path: '/messages/:id',
    element: <SingleMessage />,
    permission: 'messages'
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/bills',
    element: <Bills />,
    permission: 'bills'
  },
  {
    path: '/bills/:id',
    element: <BillDetails />,
    permission: 'bills'
  },

  {
    path: '/settings/roles',
    element: <Roles />,
    permission: 'roles'
  },
  {
    path: '/bookings',
    element: <Bookings />,
    permission: 'bookings'
  },
  {
    path: '/admins',
    element: <Admins />,
    permission: 'admins'
  },
  {
    path: '/account/profile',
    element: <Profile />
  },
  {
    path: '/settings/categories',
    element: <Categories />,
    permission: 'categories'
  },
  {
    path: '/settings/rate-types',
    element: <RateTypes />,
    permission: 'rate-types'
  },
  {
    path: '/settings/relative-types',
    element: <RelativeTypes />,
    permission: 'relative-types'
  },
  {
    path: '/settings/email-templates',
    element: <EmailTemplates />,
    permission: 'email-templates'
  },
  {
    path: '/settings/features',
    element: <Features />,
    permission: 'features'
  },
  {
    path: '/settings/services',
    element: <Services />,
    permission: 'services'
  },
  {
    path: '/settings/packages',
    element: <Packages />,
    permission: 'packages'
  },
  {
    path: '/settings/regions',
    element: <Regions />,
    permission: 'regions'
  },
  {
    path: '/settings/cities',
    element: <Cities />,
    permission: 'cities'
  },
  {
    path: '/settings/districts',
    element: <Districts />,
    permission: 'districts'
  },
  {
    path: '/settings/advanced',
    element: <AdvancedSettings />,
    permission: 'advanced-settings'
  },

  ...globalRoutes
];

const names = [
  'dashboard',
  'centers',
  'center_owners',
  'invoices',
  'offers',
  'reports',
  'messages',
  'notifications',
  'users',
  'admins',
  'categories',
  'roles',
  'sub_categories',
  'services',
  'features',
  'email_templates',
  'email_notifications',
  'regions',
  'cities',
  'districts',
  'settings'
];

export { authenticationRoutes, globalRoutes, names, routes };
