import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Image from 'src/components/shared/Image';
import useLogout from 'src/hooks/useLogout';
import usePermissions from 'src/hooks/usePermissions';
import { RootState } from 'src/store';
import { MoveToBottom, MoveToTop } from '../animations';

interface RouterInterface {
  title: string;
  path: string;
  icon: string;
  permission?: string | string[];
}

const routes: RouterInterface[] = [
  {
    title: 'dashboard',
    permission: 'dashboard',
    icon: 'ri:home-5-line',
    path: '/'
  },

  {
    title: 'centers',
    permission: 'centers',
    icon: 'ri:hospital-line',
    path: '/centers'
  },
  {
    title: 'bookings',
    permission: 'bookings',
    icon: 'uil:invoice',
    path: '/bookings'
  },
  {
    title: 'offers',
    permission: 'offers',
    icon: 'nimbus:discount-circle',
    path: '/offers'
  },
  {
    title: 'notifications',
    permission: 'notifications',
    icon: 'ph:bell',
    path: '/notifications'
  },
  {
    title: 'messages',
    permission: 'messages',
    icon: 'ant-design:message-outlined',
    path: '/messages'
  },
  {
    title: 'admins',
    permission: 'admins',
    icon: 'ri:admin-line',
    path: '/admins'
  },
  {
    title: 'bills',
    permission: 'bills',
    icon: 'iconamoon:invoice-light',
    path: '/bills'
  },
  {
    title: 'logs',
    permission: 'logs',
    icon: 'tdesign:bug-report',
    path: '/logs'
  },

  {
    title: 'settings',
    permission: [
      'categories',
      'rate-types',
      'relative-types',
      'email-templates',
      'advanced-settings',
      'website-settings',
      'features',
      'services',
      'packages',
      'regions',
      'cities',
      'districts',
      'roles'
    ],
    icon: 'ri:settings-line',
    path: '/settings'
  }
];

const SideBar: FC = () => {
  const location = useLocation();
  const menuContainer = useRef<HTMLElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { handleLogged } = useLogout();
  const { t } = useTranslation();

  const { hasPermissions } = usePermissions();

  const openCloseMenu = useCallback(function (ev: React.MouseEvent) {
    const currentTarget = ev.target as HTMLElement | null;
    if (currentTarget === menuContainer?.current) {
      menuContainer?.current?.classList.add('hidden');
    }
  }, []);

  const closeMenu = useCallback(function () {
    menuContainer?.current?.classList.add('hidden');
  }, []);

  return (
    <aside
      className="w-full xl:max-w-[18rem] shrink-0 bg-gray-800/20 shadow-2xl shadow-gray-600/5 fixed xl:relative z-20 hidden xl:block"
      ref={menuContainer}
      data-type="menu"
      onClick={openCloseMenu}
    >
      <div className="w-full max-w-[18rem] py-0 px-4 bg-gray-100 sticky top-0 min-h-screen max-h-screen overflow-y-auto flex flex-col border-r rtl:border-r-none rtl:border-l border-gray-200">
        <div className="w-full border-b border-b-gray-100 flex items-center justify-between py-4">
          <Link
            to="/"
            className="shrink-0"
          >
            <img
              src="/images/black-logo.svg"
              alt="website logo"
              className="w-full max-w-[8rem] "
            />
          </Link>
          <button
            className="shrink-0 text-gray-500 flex xl:hidden items-center justify-center"
            onClick={closeMenu}
          >
            <Icon
              icon="line-md:menu-to-close-transition"
              width={18}
            />
          </button>
        </div>
        <nav className="flex-1 py-4">
          <ul className="space-y-1">
            {routes.filter(hasPermissions).map((route: RouterInterface, index: string | number) => (
              <motion.li
                key={index}
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
              >
                <Link
                  to={route.path}
                  className={[
                    'w-full flex items-center gap-4 text-base py-2 px-3 rounded ',
                    new RegExp('^' + route.path + '$', 'gi').test(location.pathname)
                      ? 'bg-primary/5 text-primary font-semibold'
                      : 'text-gray-500'
                  ].join(' ')}
                >
                  <span className="shrink-0">
                    <Icon
                      icon={route.icon}
                      width={20}
                    />
                  </span>
                  <span>{t(`pages.${route.title}`)}</span>
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
        <div className="w-full border-t border-t-gray-100 flex items-center justify-between py-4">
          <Link
            to="/account/profile"
            className="shrink-0 inline-flex gap-4"
          >
            <Image
              src={user?.image}
              className="shrink-0 w-10 h-10 rounded-full"
            />
            <div className="flex-1 grid">
              <motion.p
                animate="visible"
                initial="hidden"
                variants={MoveToTop}
                className="text-base font-bold text-gray-800 tabular-nums"
              >
                {user?.name || 'UNKNOWN'}
              </motion.p>
              <motion.a
                animate="visible"
                initial="hidden"
                variants={MoveToBottom}
                className="text-sm font-medium text-gray-500 line-clamp-1"
              >
                {user?.email || 'UNKNOWN'}
              </motion.a>
            </div>
          </Link>
          <button
            className="shrink-0  text-gray-500 flex items-center justify-center"
            onClick={handleLogged}
          >
            <Icon
              icon="heroicons-outline:logout"
              width={24}
            />
          </button>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
